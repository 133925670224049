import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "MIS Executive",
    type: "Full Time",
    level: "Mid Level",

    description:
        "The MIS Executive is responsible for collecting, cleaning, analyzing, and interpreting data to provide valuable insights that drive informed decision-making within the organization. This role involves developing and maintaining reporting systems, dashboards, and other analytical tools to track key performance indicators (KPIs) and monitor business performance.",

    responsibilities: [
        "Collect data from a variety of sources, including ERP and CRM systems, as well as other relevant databases.",
        "Clean and validate your data to ensure accuracy and consistency.",
        "Identify and address data quality issues.",
        "Identify trends, patterns, and irregularities in data by applying statistical techniques and data visualization tools.",
        "Create and maintain frequent reports, dashboards, and assessments to monitor key performance indicators.",
        "Present your findings in a clear and simple manner, including visualization and reporting strategies.",
        "Design, create, and deploy data-driven solutions including dashboards and reports.",
        "Maintain and update the existing reporting systems and tools.",
        "Collaborate with IT teams to maintain data integrity and system performance.",
        "Work closely with company stakeholders to determine their data needs and requirements.",
        "Provide data-driven insights to help make strategic decisions.",
        "Identify opportunities for process improvement and cost savings.",
        "Maintain compliance with data privacy and security regulations.",
        "Establish suitable data governance policies and procedures.",
        

    ],

    requirements: [
       " Bachelor's degree in computer science, IT, or similar.",
       " Master's degree preferable.",
       " Experience managing a team.",
      "  Practical experience with a variety of software applications.",
        "Attention to detail and excellent analytical skills.",
       " Effective communication and interpersonal skills.",
      "  Ability to motivate and inspire staff.",
       " Strong leadership abilities and good time management skills.",
       " Excellent problem-solving and management skills.",
       " Ability to train junior staff.",
        
        
 
     ],

  
    noOfOpenings: 1
};

const MisExecutive = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default MisExecutive;
