import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
    title: "Procurement Supervisor",
    type: "Full Time",
    level: "Mid Level",

    education: [
        "Bachelor's degree in Administration, Business Studies, or a related field.",
    ],

    description:
        "We are seeking a highly motivated and experienced Procurement Supervisor to join our dynamic team. The ideal candidate will be responsible for overseeing all aspects of the procurement process, from supplier selection and negotiation to purchase order management and vendor performance evaluation. This role requires a strong understanding of procurement principles, excellent negotiation skills, and a keen eye for detail.",


    qualifications: [
        "Education: Bachelor's degree in Administration, Business Studies, or a related field.",
        "Experience: 3-4 years of hands-on experience in procurement.",
        "Vehicle:Must have two wheeler and a valid driving licence.",
        "Skills:",
        "Strong negotiation and communication skills.",
        "Proficiency in procurement software and tools.",
        "Excellent analytical and problem-solving abilities.",
        "Attention to detail and accuracy.",
        "Ability to work under pressure and meet deadlines.",
        "Knowledge of procurement regulations and compliance standards.",

    ],

    responsibilities: [
        "Identify and evaluate potential suppliers and vendors to ensure optimal sourcing.",
        "Establish and maintain strong relationships with suppliers to foster long-term partnerships.",
        "Monitor supplier performance against established KPIs (Key Performance Indicators) and take corrective action when necessary.",
        "Negotiate favourable contracts with suppliers to optimise pricing, terms, and conditions.",
        "Create, process, and track purchase orders to ensure timely delivery of goods and services, including requisition review, purchase order generation, and invoice processing.",
        "Monitor inventory levels to optimise stock levels and minimise holding costs.",
        "Identify and mitigate potential risks in the supply chain, such as supply shortages, price fluctuations, and quality issues.",
        "Onboard and manage a diverse pool of reliable and qualified vendors.",
        "Regularly evaluate vendor performance and take corrective actions as needed.",
        "Develop a vendor rating system to assess and prioritise suppliers.",
        "Foster strong relationships with key vendors to ensure timely and efficient deliveries.",
        "Coordinate with logistics providers to ensure timely and cost-effective delivery of goods.",
        "Track and monitor shipments to minimise delays and disruptions.",
        "Resolve any logistics issues or discrepancies promptly.",
        "Implement effective inventory management practices to optimise stock levels and reduce carrying costs.",
        "Assign tasks, monitor performance, and provide feedback to team members.",
        "Foster a positive and collaborative work environment.",
        "Identify training and development needs for team members.",
        "Encourage continuous improvement and innovation within the procurement team.",
        "Utilise e-procurement tools to streamline procurement processes and improve efficiency.",

    ],
    noOfOpenings: 1
};

const Procurement = () => {
    return <CareerDetailPage careerDetails={careerDetails} />;
};

export default Procurement;
