import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
  title: "Administrative Assistant",
  type: "Full Time",
  level: "Mid Level",
//   education: [
//     "Bachelor’s degree in graphic design, industrial design, or interior design, or equivalent experience",
//     "Five to eight years of experience in graphic design",
//   ],
  description:
   "This is a full-time position where you will provide comprehensive administrative support to ensure efficient office operations. You will be responsible for a variety of tasks, including event logistics, office management, finance support, and maintaining vendor relationships.",

   qualifications: [
    "Bachelor's degree in Business Administration, Business Studies or related field",
    "Strong communication and writing skills in both Nepali and English.",
    "Proficiency in Microsoft Office Suite (Word, Excel, PowerPoint).",
    "Knowledge of banking and taxation procedures.",
    "Valid two-wheeler driving licence and ability to travel outside the organisation.",
   "Experience in government liaison and regulatory compliance.",
   "Knowledge of specific industry regulations and standards.",
    "Ability to communicate in Hindi language will be a plus.",
    
],
responsibilities: [
  "Manage and maintain office supplies and equipment.",
 " Handle incoming and outgoing mail and correspondence.",
 " Organise and maintain office files and records.",
 " Schedule and coordinate meetings and appointments.",
 " Assist with event planning and logistics.",
 " Provide general administrative support to various departments.",
 " Assist with financial tasks, such as invoice processing and expense reports.",
 " Maintain accurate records of financial transactions.",
 " Prepare and submit necessary financial documents.",
 " Represent the organisation at government offices.",
  "Prepare and submit required documents to government authorities.",
  "Liaise with government officials to resolve issues and obtain necessary permits.",
  "Perform any other administrative tasks as assigned.",
  
  
  ],
 // preferredQualifications:[
 //   "Experience with office management software (e.g., Google Suite, Microsoft Office 365).",
  //  "Knowledge of basic accounting principles.",
   // "Familiarity with basic HR procedures."
 // ]

 noOfOpenings: 1,
};

const AdminAssistant = () => {
  return <CareerDetailPage careerDetails={careerDetails} />;
};

export default AdminAssistant;
